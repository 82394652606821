<template>
  <div class="index-wrap">
    <div class="head-box">
      <img class="logo" src="../assets/20240116/logo.png" alt="" srcset="" />
      <div class="nav-box">
        <div class="nav-list" @click="topScroll(0)">
          首页
          <img
            src="../assets/20240116/icon_selected.png"
            :style="{ opacity: check == 0 ? 1 : 0 }"
          />
        </div>
        <div class="nav-list" @click="topScroll(1)">
          我们的优势
          <img
            src="../assets/20240116/icon_selected.png"
            :style="{ opacity: check == 1 ? 1 : 0 }"
          />
        </div>
        <div class="nav-list" @click="topScroll(2)">
          省钱攻略
          <img
            src="../assets/20240116/icon_selected.png"
            :style="{ opacity: check == 2 ? 1 : 0 }"
          />
        </div>
        <div class="nav-list" @click="topScroll(3)">
          关于我们
          <img
            src="../assets/20240116/icon_selected.png"
            :style="{ opacity: check == 3 ? 1 : 0 }"
          />
        </div>
      </div>
    </div>
    <div>
      <img
        class="banner"
        src="../assets/20240116/banner2.png"
        alt=""
        srcset=""
      />
    </div>
    <div class="tips_box">
      <p>重要通知</p>
      <p>尊敬的用户：</p>
      <div>
        为了向您提供更优质的服务，话费权益包功能将于2023年02月02日12:00时进行系统升级维护。维护期间，新用户将无法使用此功能，所有老用户均可正常使用，或选择无理由退款。系统升级维护完成后，将及时发布通知，请您注意查看通知。
      </div>
      <div>
        维护期间，如您有任何疑问，可及时与客服联系，客服联系电话：4000445005
      </div>
      <div>感谢您的理解与支持！</div>
      <div class="align-right">2023年02月02日</div>
      <div class="align-right">成都优一只信息科技有限公司</div>
    </div>
    <div class="center">
      <img class="pic_6" src="../assets/20240116/pic_6.png" alt="" srcset="" />
    </div>
    <div class="center" ref="pic8">
        <div class="p8Main">
          <img class="pic_8" src="../assets/20240116/pic_8.png" alt="" srcset="" />
          <img @click="downFn" class="downClass" src="../assets/20240116/down.png" alt="" srcset="" />
        </div>
    </div>

    <div class="banner-wrap">
      <div class="banner-title center">
        <img
          class="pic_5"
          ref="pic5"
          src="../assets/20240116/pic_5.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="banner-nav center">
        <div
          class="b-n-l"
          :class="{ active: bannerIndex === 0 }"
          @click="bannerIndex = 0"
        >
          淘宝/天猫省钱
        </div>
        <div
          class="b-n-l b-n-l-4"
          :class="{ active: bannerIndex === 1 }"
          @click="bannerIndex = 1"
        >
          京东省钱
        </div>
        <div
          class="b-n-l b-n-l-4"
          :class="{ active: bannerIndex === 2 }"
          @click="bannerIndex = 2"
        >
          外卖省钱
        </div>
      </div>
      <div class="banner-box">
        <div class="next-pre">
          <img
            class="icon_last"
            @click="handlePre"
            src="../assets/20240116/icon_last.png"
            alt=""
            srcset=""
          />
          <img
            class="icon_next"
            @click="handleNext"
            src="../assets/20240116/icon_next.png"
            alt=""
            srcset=""
          />
        </div>
        <div class="banner-ls-box-h" v-if="bannerIndex === 0">
          <div
            class="banner-ls-box"
            :style="{ transform: `translateX(${leftPx0})` }"
          >
            <img src="../assets/20240116/xm_step_1.png" alt="" srcset="" />
            <img src="../assets/20240116/xm_step_2.png" alt="" srcset="" />
            <img src="../assets/20240116/xm_step_3.png" alt="" srcset="" />
            <img src="../assets/20240116/xm_step_4.png" alt="" srcset="" />
          </div>
        </div>
        <div class="banner-ls-box-h" v-if="bannerIndex === 1">
          <div
            class="banner-ls-box"
            :style="{ transform: `translateX(${leftPx1})` }"
          >
            <img src="../assets/20240116/jd_step_1.png" alt="" srcset="" />
            <img src="../assets/20240116/jd_step_2.png" alt="" srcset="" />
            <img src="../assets/20240116/jd_step_3.png" alt="" srcset="" />
            <img src="../assets/20240116/jd_step_4.png" alt="" srcset="" />
          </div>
        </div>
        <div class="banner-ls-box-h" v-if="bannerIndex === 2">
          <div
            class="banner-ls-box"
            :style="{ transform: `translateX(${leftPx2})` }"
          >
            <img src="../assets/20240116/wm_step_1.png" alt="" srcset="" />
            <img src="../assets/20240116/wm_step_2.png" alt="" srcset="" />
            <img src="../assets/20240116/wm_step_3.png" alt="" srcset="" />
            <img src="../assets/20240116/wm_step_4.png" alt="" srcset="" />
          </div>
        </div>
      </div>
    </div>
    <div class="footer" ref="footer">
      <img class="pic" src="../assets/20240116/pic.png" alt="" srcset="" />
      <div class="des-box">
        <p class="footer-title">关于我们</p>
        <p>·商务合作/问题咨询</p>
        <p>
          ·在线客服 如您遇到任何疑问，欢迎联系咨询</p>
        <p>
          ·客服电话：400-999-0706</p>
        <p>
          ·服务时间：9:00-18:00
          </p>
        <p class="icp">
          <a
            href="https://beian.miit.gov.cn"
            style="color: #fff;margin-right:20px"
            target="_blank"
            rel="noopener noreferrer"
            >桂ICP备2023004121号-1</a
          >
          广西佳畅网络科技有限公司
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: 0,
      bannerIndex: 0,
      leftPx0: "0",
      leftPx1: "0",
      leftPx2: "0",
      topPic8: 0,
      topPic5: 0,
      topFooter: 0,
      check: 0,
    };
  },
  methods: {
    downFn(){
      window.openinstallDwon('znry21')
    },
    handlePre() {
      this["leftPx" + this.bannerIndex] = "0";
    },
    handleNext() {
      this["leftPx" + this.bannerIndex] = "-385px";
    },
    topScroll(i) {
      let top = 0;
      this.check = i;
      switch (i) {
        case 0:
          top = 0;

          break;
        case 1:
          top = this.topPic8;
          break;
        case 2:
          top = this.topPic5;
          break;
        case 3:
          top = this.topFooter;
          break;
      }
      window.scrollTo({
        top,
        behavior: "smooth",
      });
    },
    scleTop() {
      this.topPic8 = this.$refs.pic8.offsetTop - 87;
      this.topPic5 = this.$refs.pic5.offsetTop - 87;
      this.topFooter = this.$refs.footer.offsetTop - 87;
    },
  },
  mounted() {
    setTimeout(() => {
      this.scleTop();
    }, 200);
    window.onresize = () => {
      this.scleTop();
    };
  },
};
</script>

<style lang="scss">
.index-wrap {
  padding-top: 87px;
  max-width: 1920px;
  margin: 0 auto;
}
.head-box {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1920px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 87px;
  background: #ffffff;
  padding: 0 360px;
  box-shadow: 1px 10px 20px rgba(0,0,0,0.1);
  .logo {
    width: 180px;
    height: 40px;
  }
  .nav-box {
    display: flex;
    align-items: center;
    .nav-list {
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #1A1A1A;
      font-weight: bold ;
      margin-left: 119px;
      cursor: pointer;
      white-space: nowrap;
      img {
        margin: 5px auto 0;
        display: block;
        width: 38px;
        height: 6px;
      }
    }
  }
}
.tips_box{
  display: none;
  position: absolute;
  top: 180PX;
  right: 20PX;
  max-width: 40%;
  line-height:32px;
  color: #fff;
  padding: 10PX 10PX;
  background: rgba(0,0,0,0.5);
  border-radius: 16PX;
}
.tips_box>div{
  text-indent: 32PX;
  font-size: 16PX;
}
.tips_box>p{
  font-size: 16PX;
}
.tips_box .align-right{
  text-align: right;
}
.center {
  text-align: center;
}
.p8Main{
  margin: 0 auto;
  width: 990px;
  position: relative;
  .downClass{
    position: absolute;
    bottom: 33px;
    right: 238px;
    width: 375px;
    height: 78px;
    cursor: pointer;
  }
}

.banner,
.pic_7 {
  width: 100%;
}
.pic_6 {
  width: 937px;
  margin-top: 29px;
}
.pic_7 {
  margin-top: 45px;
}
.pic_8 {
  width: 990px;
  margin-top: 179px;
}
.banner-wrap {
  .banner-title {
    margin-top: 132px;
    .pic_5{
      width: 422px;
    }
  }
  .banner-nav {
    width: 1140px;
    margin: 43px auto 0;
    display: flex;
    align-items: center;
    .b-n-l {
      flex: 1;
      text-align: center;
      color: #f64847;
      font-size: 24px;
      cursor: pointer;
    }
    .active {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        width: 137px;
        left: 50%;
        transform: translateX(-50%);
        bottom: -5px;
        height: 3px;
        background: linear-gradient(270deg, #ff6f6f 0%, #f64847 100%);
        border-radius: 2px;
      }
    }
    .b-n-l-4 {
      &:after {
        width: 78px;
      }
    }
  }
  .banner-box {
    width: 1140px;
    margin: 28px auto 0;
    .banner-ls-box-h {
      width: 100%;
      overflow: hidden;
    }
    .banner-ls-box {
      display: flex;
      transition: 0.3s;
      img {
        width: 360px;
        height: 428px;
        margin-right: 25px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .next-pre {
      width: 100%;
      position: relative;
      z-index: 1;
      img {
        position: absolute;
        width: 74px;
        height: 74px;
        top: 155px;
        cursor: pointer;
      }
      .icon_last {
        left: -37px;
      }
      .icon_next {
        right: -37px;
      }
    }
  }
}
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 111px;
  background: #FE4F22;
  height: 462px;
  padding: 16px 407px 16px 443px;
  width: 100%;
  .pic {
    width: 376px;
    height: 429px;
  }
  .des-box {
    width: 595px;
    margin-left: 73px;
    .footer-title {
      font-size: 47px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 66px;
      margin-bottom: 3px;
    }
    p {
      font-size: 28px;
      color: #ffffff;
      line-height: 55px;
      white-space: nowrap;
    }
  }
}
</style>
